import pageType from 'Addons/search/pageType.ts';

export default {
  pageNumberParamName: 'pageNumber',
  page: {
    typeToSelectorMap: {
      [pageType.home]: '#home, body.home-page',
      [pageType.category]: '#category',
      [pageType.brand]: 'body.search-groups',
      [pageType.product]: 'input#catalogid[name="item_id"]',
    },
  },
};
