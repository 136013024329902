import { useSelector } from 'react-redux';

import { createSelectedVehicleSelectionSelector } from 'Core/selectors/fitmentSearch/index.js';
import { productDataSelector } from 'Core/selectors/product.ts';
import { getUriFromRequest } from 'Modules/converter/index.js';

import type { FC } from 'react';
import type { TemplateFunction } from 'Components/types.ts';
import type { FacetRequest } from 'Models/index.ts';
import type { ServerModel } from 'Modules/serverApi/types.ts';

type GetSearchLinkProps = {
  facetSelection?: FacetRequest[];
  customHref?: string;
};

type ProductDataType = {
  field: string;
  value: unknown;
}[];

type Params = {
  productData: ServerModel.SearchItem | ProductDataType;
  getSearchLink: (props: GetSearchLinkProps) => string;
};

type Props = {
  template: TemplateFunction<Params>;
  fields?: string[];
};

const ProductData: FC<Props> = ({ template, fields }) => {
  const productData = useSelector(productDataSelector);

  const productColors = productData?._Color;
  const colorSelection = productColors ? (productColors.toFacetValues('_Color') as FacetRequest[]) : [];
  const vehicleSelection = useSelector(createSelectedVehicleSelectionSelector);

  if (!productData || !Object.keys(productData).length) {
    return null;
  }

  const getSearchLink = ({ facetSelection = [], customHref, withVehicle = true }) => {
    const selection = [...facetSelection, ...colorSelection, ...(withVehicle ? vehicleSelection : [])].filter(
      Boolean,
    );

    const {
      pathname: defaultPathname,
      search,
      hash,
    } = getUriFromRequest({ selection }, { goToSearchPage: true });

    return `${customHref || defaultPathname}${search}${hash}`;
  };

  const data = fields?.length
    ? Object.entries(productData)
        .filter(([field]) => fields.includes(field))
        .map(([field, value]) => ({ field, value }))
    : productData;

  return template.call({ productData: data, getSearchLink });
};

export default ProductData;
