import { SearchPage } from 'Components'
import TemplateSearchPage from 'Stores/_default-store/Templates/SearchPage.rt'
import { SearchBoxDialogButton } from 'Components'
import TemplatesearchBoxdialogButtonAdaptable from 'Stores/_default-store/Templates/searchBox/dialogButtonAdaptable.rt'
import { SearchHeader } from 'Components'
import { ProductData } from 'Components'
import TemplateproductproductData from 'Stores/abolox/Templates/product/productData.rt'
import { FacetPanel } from 'Components'
import { SearchResult } from 'Components'
import { ContextDialog } from 'Components'
import TemplateDialog from 'Stores/_default-store/Templates/Dialog.rt'
import { SearchBoxDialog } from 'Components'
import TemplateSearchBoxDialog from 'Stores/_default-store/Templates/SearchBoxDialog.rt'

export const compMap = {
  SearchPage,
SearchBoxDialogButton,
SearchHeader,
ProductData,
FacetPanel,
SearchResult,
ContextDialog,
SearchBoxDialog
} 
export const tplMap = {
  'SearchPage': TemplateSearchPage,
'searchBox/dialogButtonAdaptable': TemplatesearchBoxdialogButtonAdaptable,
'product/productData': TemplateproductproductData,
'Dialog': TemplateDialog,
'SearchBoxDialog': TemplateSearchBoxDialog
};